.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* bannae css */

.mainSlider .swiperContainer2 {
  background-image: url("../public/images/banner/lab-banner.jpg");
  width: 100%;
  height: 800px;
  background-position: center;
  background-size: cover;
}

.mainSlider .swiperContainer2 .swipermain2 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 750px;
}

.mainSlider .swiperContainer {
   background-image: url("../public/images/banner/fll-2019-open-lab-story-1.jpg");
  width: 100%;
  height: 800px;
  background-position: center;
  background-size: cover;
}

.mainSlider .swiperContainer .swipermain {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 750px;
}

.vavbutn {
  display: none;
}

.vavbutn {
  text-align: right;
  /* display: block; */
  background: transparent;
  font-size: 25px;
  color: rgb(17, 17, 17);
  border: transparent;
  outline: none;
  margin-bottom: 12px;
}

@media (max-width: 560px) {
  .vavbutn {
    display: block;
    background: transparent;
    font-size: 25px;
    color: rgb(14, 13, 13);
    border: transparent;
    outline: none;
    margin-bottom: 12px;
  }
}


.mt{
margin-top: 100px;
border-radius: 3px;
}


.mb{
margin-top: 70px;
border-radius: 3px;
}









.navbar{
margin-right: 50px;
}

.navbar-nav .nav-item .nav-link{
 margin: 20px;
}
.navbar-dark .navbar-brand{
    margin-right: -45px;
}

  /* /=================================Header start=============================/ */
  .navbar {
  z-index: 999;
  position: sticky;
  top: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 70px;
    /* justify-content: space-between; */
    align-items: center;
    background-color: white;
    /* box-shadow: 3px 3px 15px grey; */
  }
  
  #navbarSupportedContent ul {
    padding-bottom: 10px;
  }
  
  .nav-item {
    margin: 0 5px;
    border-top: 3px solid transparent;
    transition: 0.5s all;
    color: red;
  }
  
  .nav-item:hover {
    border-color: #101014;
  }
  
  .nav-item .nav-link {
    list-style: none;
    text-decoration: none;
    position: relative;
    color: rgb(245, 239, 239);
    font-size: 20px !important;
    font-weight: 600;
  }
  
  .nav-link.active {
    border-color: #0c0c10;
  }
  
  .d-flex {
    text-decoration: none;
  }
  
  .logo-wrapper img {
    height: 35px;
  }
  
  .logo-wrapper h1 {
    color: white;
    font-size: 20px;
    align-items: center;
    
  }
  
  .vavbutn {
    display: none;
  }
  
  .desklink ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 22px;
  }
  
  .desklink ul li {
    list-style: none;
    text-decoration: none;
    padding: 0 11px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    font-size: 15px;
    color: #696969;
    font-family: "Lora', serif";
  }
  .desklink ul li:hover {
    color: #FFB400;
  }
 .li{
  margin-left: 19px;
 }
  
  .desklink ul li .nhlink {
    text-decoration: none;
    color: white;
    font-weight: 600;
  }
  
  .outslidmain {
    background-color: #59fefd;
    border-radius: 50%;
    /* margin: 30px; */
    padding: 5px;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .outslidmain .outslidmainImg {
    width: 85%;
    /* border-radius: 50%; */
  }
  
  
  .image1 {
    background-repeat: no-repeat;
    overflow: hidden;
  }
  
  .imgCard {
    position: absolute;
    top: 210px !important;
    left: 150px !important;
    width: 27%;
    background-color: #fff !important;
  }
  
  .imgCard1 {
    position: absolute;
    top: 210px !important;
    left: 150px !important;
    height: auto;
    width: 26%;
    text-align: center;
    
    background-color: transparent;
    padding: 10px;
    padding-bottom: 28px;
    letter-spacing: 3px;
    z-index: 90;
    border-radius: 5px;
  }
  
  .imgCard1 h2 {
    padding-top: 28px;
    font-size: 34px;
    
    font-weight: 600;
    /* font-style: unset; */
    letter-spacing: 1.5px;
    color: #112042;
  }
  
  .imgCard .imgCardText {
    padding-top: 15px;
    font-size: 34px;
    
    font-weight: 600;
    letter-spacing: 0.6px;
    color: #112042;
    text-align: center;
  }
  .imgCard h2 {
    font-size: 40px;
    /* font-family: 'Alkatra', "cursive"; */
    
    color: #112042;
    text-align: center;
  }
  
  .imgCard1 .paragraph {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .imgCard1 .paragraph .paragraph1 {
    padding: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    
    letter-spacing: 0px;
    word-spacing: 0px;
    text-align: center;
  }
  /* padding: 32px;
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: 15px;
    font-family: "montserrat",sans-serif;
    letter-spacing: 0px;
    text-align: center;
    color: #1d1d1d; */
  
  .imgCard1 .imgcardButton {
    margin: 1px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    border: none;
    border-radius: 5px;
    color: #112042;
    letter-spacing: 0.6px;
    
    text-decoration: none;
  }
  .imgCard1 .imgcardButton:hover {
    color: #ff808b;
  }
  .imgCard1 .dash {
    display: block;
    width: 45px;
    height: 1px;
    background-color: #ff808b;
    margin: 15px auto;
    margin-top: 9px;
  }
  .imgCard1 .dash2 {
    display: block;
    width: 45px;
    height: 1px;
    background-color: #ff808b;
    margin: 15px auto;
    /* margin-top: 0px auto; */
  }
  
  .imgCard h1 {
    margin-bottom: 10px;
    font-size: 80px !important;
    
    text-shadow: 1px 1px 1px gray;
    color: whitesmoke;
    line-height: 100%;
  }
  
  .imgCardButton {
    margin: 1px;
    padding: 6px;
    background-color: rgb(4, 14, 40);
    font-size: 10px;
    text-align: center;
    float: right;
    border: none;
    border-radius: 5px;
    color: white;
    letter-spacing: 0.6px;
    
  }
  
  .img-card-button span {
    font-size: 12px;
    color: white;
    margin-left: 5px;
  }
  
  .box {
    margin: 10px;
    background-color: white;
    height: auto;
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  
  .box > .rows {
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: row;
  }
  
  .box > .rows > .column {
    margin: -3px;
    padding-right: 7px;
    margin-top: -7px;
  }
  
  .box > .rows > .column > h1 {
    text-align: left;
    
    font-style: italic;
    border-radius: 10px;
    font-size: 34px;
  }
  
  .box .rows .column .join {
    font-size: 26px;
    color: #112042;
    
  }
  
  .box .rows .column .join {
    /* text-decoration: 3px underline #ff808b; */
    transition: 1s;
  }
  
  .box-link {
    display: flex;
    justify-content: center;
    text-align: center;
    color: black;
  }
  
  .box-link > li {
    cursor: pointer;
    font-size: 22px;
    list-style: none;
  }
  
  .box-link li .link {
    color: rgb(4, 14, 40);
    padding: 7px;
  }
  
  .box-link li .link :hover {
    transform: translate(-10px, -10px);
    transition: 1.2s;
  }
  
  /* .box1 {
   background-color:#ededf3 ;
  } */
  .box-3 {
    background-color: rgb(233, 228, 228);
    height: auto;
  }
  
  .box-3 .rows {
    display: grid;
    grid-template-columns: 6fr 6fr;
  }
  
  .box-3 .rows .column1 {
    background-color: #0c3157;
  }
  
  .box-3 .rows .column2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .box-3 .rows .column2 .column2Img {
    /* height: 100%; */
    width: 100%;
  }
  
  .box-3 .rows .column2 .column2Img > img {
    width: 100%;
    /* height: 100%; */
  }
  
  .box-3 .column {
    display: block;
    text-align: center;
  }
  
  .box-3 .column > p {
    margin: 20px;
    color: #fff;
    font-weight: bold;

  }
  
  .box-3 .column > h1 {
    /* margin-top: 50%; */
    /* font-size: 100px;  */
    font-weight: 500;
    color: white;

  }
  
  .box-3 .column > h3 {
    color: #fff;
    justify-content: center;
    text-align: center;
    padding: 25px;
    
    
    margin-top: 50px;
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 1.8;
  }
  
  .box-3 .column > h5 {
    text-align: center;
    color: #fff;
    font-size: 18px;

  }
  
  .box-3 .column > h2 {
    font-size: 20px;
    color: #fff;
    /* text-decoration:2px underline rgb(212, 209, 209); */

  }
  
  .box-4 {
    background-color: white;
    padding-top: 40px;
    margin-bottom: -125px;
    height: 350px;
  }
  
  .box-4 h3 {
    text-align: justify;
    color: #112042;
    
    font-size: 15px;
    font-weight: bold;
  }
  
  .box-4 h1 {
    /* font-size: 70px;
    text-align: center;
    padding-top: 30px;
    color: #112042;
    font-family: "begum",serif; */
    text-align: justify;
    
    color: #112042;
    font-size: 56px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.04em;
    line-height: 1;
  }
  
  .box-4 h2 {
    padding-top: 25px;
    text-align: center;
    font-size: 30px;
    
  }
  
  .box-4 h2:hover {
    text-decoration: 3px underline #eec3af;
    transition: 1s;
  }
  
  .box-5 {
    overflow: hidden;
    position: relative;
    height: 800px;
  }
  
  .box-5 .card {
    position: absolute;
    top: 60px;
    left: 120px;
    height: 550px;
    width: 600px;
    background: transparent;
  }
  
  .box-5 .card > h1 {
    color: rgb(224, 215, 215);
    font-size: 70px;
    
    padding: 50px;
    letter-spacing: 4px;
  }
  
  .box-5 .card > button {
    margin: 5px;
    padding: 12px;
    width: 200px;
    background-color: rgb(97, 215, 220);
    text-align: center;
    margin-left: 100px;
  
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
    color: rgb(17, 16, 14);
    cursor: pointer;
  }
  
  .box-6 {
    background-color: rgba(246, 250, 251, 0.84);
  }
  
  .box-6 h1 {
    color: #112042;
    text-align: center;
    padding-top: 50px;
    font-size: 32px;
    /* font-family: "begum",serif; */
    
  }
  
  .box-6 h1 > span {
    color: red;
    
  }
  
  .box-6 p {
    text-align: center !important;
    padding: 6px;
    font-size: 20px;
    margin-top: 22px;
    letter-spacing: 1px;
    
  }
  

  
  /* ================================Media query Start=============================== */
  
  @media (min-width: 1089px) {
    .navbar {
      width: 100%;
    }
  
    .image1 img {
      width: 100%;
    }
  
    .imgCard {
      position: absolute;
      top: 200px;
      left: 50px;
      color: rgb(4, 14, 40);
    }
  
    .imgCard h1 {
      font-size: 70px;
    }
  
    .imgCardButton {
      width: 100%;
      font-size: 17px;
    }
  
    .imgCardButton {
      width: 35%;
      font-size: 14px;
    }
  
    .box1 > img {
      width: 100%;
    }
  
    .rows .column > h1 {
      font-size: 52px;
      width: 100%;
    }
  
    .box-3 .rows .column img {
      width: 100%;
    }
  
    .box-5 {
      height: 400px;
    }
  
    .box-5 img {
      width: 100%;
    }
  
    .myapprochCard2 .myapprochText1 .left3In h1 {
      font-size: 90px;
    }
  
    .myapprochCard2 .myapprochText1 .left3In h3 {
      font-size: 29px;
      line-height: 1.3;
    }
  
    .myapprochCard3 .myapprochText2 h2 {
      text-shadow: none;
      font-size: 50px;
    }
  
    .myapprochCard3 .myapprochText2 p {
      font-size: 34px;
    }
  }
  
  @media (max-width: 820px) {
    .desklink {
      display: none;
    }
  
    .moblink {
      position: absolute;
      top: 70px;
      right: 0;
      background: #212529c9;
      width: 60%;
      border-radius: 6px;
      text-align: center;
    }
  
    .moblink ul li {
      font-size: 18px;
      padding: 7px 0;
      list-style: none;
      color: #fff;
    }
  
    .vavbutn {
      text-align: right;
      display: block;
      background: transparent;
      font-size: 25px;
      color: white;
      border: transparent;
      outline: none;
      margin-bottom: 12px;
    }
  
    .image1 img {
      width: 100%;
    }
  
    .imgCard {
      /* position: absolute;
      top: 200px;
      left: 50px;
      color: rgb(4, 14, 40); */
      display: block;
    }
  
    .imgCard h1 {
      font-size: 70px;
    }
  
    .imgCardButton {
      width: 100%;
      font-size: 17px;
    }
  
    .imgCardButton {
      width: 100%;
      font-size: 12px;
    }
  
    .box1 > img {
      width: 100%;
    }
  
    .rows .column > h1 {
      font-size: 30px;
      width: 100%;
    }
  
    .box-3 .rows .column img {
      width: 100%;
    }
  
    .box-5 {
      height: 400px;
    }
  
    .box-5 img {
      width: 100%;
    }
  
    .textslider {
      padding-top: -20px;
    }
  
    .mystoryslider {
      padding-top: 20px;
    }
  
    .mystoryImg .banner img {
      width: 100%;
    }
  
    .mystoryImg .text {
      position: absolute;
      top: 100px;
      left: 0;
    }
  
    .mystoryCard2 {
      height: auto;
    }
  
    .mystoryCard2 .text2 {
      width: 100%;
      padding: 15px;
    }
  
    .mystoryCard2 .text2 h6 {
      font-size: 28px;
    }
  
    .mystoryCard3 {
      height: 50%;
    }
  
    .mystoryCard4 .text3 {
      display: block;
    }
  
    .programCard1 {
      padding-top: 15px;
    }
  
    .programCard2 {
      padding-top: 20px;
    }
  
    .programCard3 {
      padding-top: 20px;
      padding-bottom: 10px;
    }
  
    .programCard3 .right3 .right3In h3 {
      line-height: 20px;
    }
  
    .programslider {
      padding-top: 15px;
    }
  
    .programCard4 {
      height: auto;
    }
  
    .myapprochslider {
      padding-top: 20px;
    }
  
    .myapprochImg .banner2 img {
      width: 100%;
    }
  
    .myapprochImg .myapprochCard {
      position: absolute;
      top: 194px;
      left: 16px;
    }
  
    .myapprochCard1 .myapprochText h1 {
      text-align: center;
    }
  
    .myapprochCard2 .myapprochText1 {
      display: block;
    }
  
    .myapprochCard2 .myapprochText1 .left3 {
      width: 100%;
    }
  
    .myapprochCard2 .myapprochText1 .left3In {
      width: 100%;
      height: auto;
    }
  
    .myapprochCard2 .myapprochText1 .left3In h1 {
      font-size: 56px;
    }
  
    .myapprochCard2 .myapprochText1 .left3In h3 {
      line-height: 30px;
      font-weight: 500;
    }
  
    .myapprochCard2 .myapprochText1 .right3 {
      width: 100%;
    }
  
    .myapprochCard3 {
      height: auto;
    }
  
    .myapprochCard3 .myapprochText2 {
      width: 100%;
    }
  
    .myapprochCard3 .myapprochText2 h2 {
      text-shadow: none;
      font-size: 40px;
    }
  
    .myapprochCard3 .myapprochText2 p {
      font-size: 25px;
    }
  
    .containerSlider {
      height: auto;
    }
  }
  
  @media (max-width: 560px) {
    .desklink {
      display: none;
    }
  
    .moblink {
      position: absolute;
      top: 70px;
      right: 0;
      background: #212529c9;
      width: 60%;
      border-radius: 6px;
      text-align: center;
      z-index: 999;
    }
  
    .moblink ul li {
      font-size: 18px;
      padding: 7px 0;
      list-style: none;
      color: #fff;
    }
  
    .vavbutn {
      display: block;
      background: transparent;
      font-size: 25px;
      color: rgb(9, 9, 9);
      border: transparent;
      outline: none;
      margin-bottom: 12px;
    }
  
    .image1 img {
      width: 100%;
    }
    .mystoryCard1 {

      margin-bottom: 5px;
    }
    
    .imgCard {
      width: 39%;
      height: 79%;
      top: 20px !important;
      left: 245px !important;
      bottom: 30px;
      word-spacing: 1px;
      position: absolute;
      padding-bottom: 0px;
    }
    .imgCard .imgCardText {
      text-align: center;
      padding-top: 0px;
      letter-spacing: 1.5px;
      font-weight: bold;
      
      font-size: 14px;
    }
  
    .imgCard .imgCardTextHeadPara {
      text-align: center;
      color: #1d1d1d;
      
      font-weight: 500;
      font-size: 11px;
    }
    .imgCard .imgCardTextPara {
      padding: 0px;
    }
    .imgCard .imgCardTextPara p {
      text-align: center;
      color: #1d1d1d;
      
      font-weight: 400;
      font-size: 8px;
      /* border: 1px solid red; */
      padding: 0px;
    }
    .imgCard .imgcardButton {
      padding: 0px;
      font-size: 10px;
      font-weight: bold;
      border-radius: 5px;
      color: #112042;
      /* border: 1px solid red; */
    }
    .imgCard .dashbelow {
      display: none;
      /* width: 206px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 0px; */
    }
    .imgCard .dashbelow .dashbelow1 {
      display: block;
      width: 60px;
      height: 1px;
      background-color: #ff808b;
      margin-right: 53px;
    }
  
    .imgCard1 h2 {
      text-align: center;
      padding-top: 0px;
      /* margin-right: 190px; */
      letter-spacing: 1.5px;
      
      font-size: 12px;
    }
    .imgCard1 .paragraph .paragraph1 {
      text-align: center;
      
      font-weight: 400;
      margin: 0 0 0px;
      font-size: 10px;
      padding: 0px;
    }
    .imgCard1 .imgcardButton {
      text-align: center;
      
      font-weight: 400;
      margin: 0 0 0px;
      font-size: 10px;
      padding: 0px;
    }
  
    .imgCard1 .dash {
      padding: 0px;
      margin: 0px 20px;
    }
    .imgCard1 {
      width: 39%;
      height: 74%;
      top: 30px !important;
      left: 20px !important;
      bottom: 30px;
      word-spacing: 1px;
    }
  
    /* .imgCard {
      display: none;
      width: 45%;
      height: 90%;
      top: 10px !important;
      left: 20px !important;
      bottom: 30px;
    } */
  
    .rows .column > h1 {
      width: 100%;
    }
  
    .box > .rows > .column > h1 {
      font-size: 14px;
    }
  
    .box > .rows > .column .box-link {
      display: none;
    }
  
    .containerSlider .MainSlider h3 {
      line-height: 24px;
      font-size: 20px;
      
    }
    .containerSlider .MainSlider span {
      color: rgb(250, 56, 104);
      font-size: 27px;
      font-weight: bold;
      /* position: absolute; */
    }
  
    .box1 {
      padding-top: 10px;
    }
  
    .box-3 .rows .column1 {
      width: 200%;
    }
  
    .box-3 .rows .column2 {
      display: none;
    }
  
    .box-3 .column > h3 {
      padding: 0px;
      font-size: 20px;
    }
  
    .box-4 h3 {
      font-size: 16px;
    }
  
    .box-4 h1 {
      font-size: 28px;
    }
  
    .box-4 h3 {
      font-size: 12px;
    }
  
    .box-5 {
      height: auto;
    }
  
    .box-5 img {
      width: 100%;
    }
  
    .box-5 .card {
      display: none;
    }
  
    .box-6 h1 {
      font-size: 32px;
      margin-top: 24px;
    }
  
    .footer {
      height: auto;
    }
  
    .footer .Mainfooter {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 30px;
    }
  
    .footer .Mainfooter .left {
      height: 20%;
      padding: 10px;
    }
  
    .footer .Mainfooter .right {
      height: 30%;
      padding: 10px;
    }
  
    .middle .footImg {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .mystoryImg .banner img {
      width: 100%;
    }
  
    .mystoryImg .text {
      position: absolute;
      top: 100px;
      left: 0;
    }
  
    .mystoryImg .text h2 {
      font-size: 42px;
    }
  
    .mystoryCard {
      height: 35%;
      padding: 20px;
    }
  
    .mystoryCard .text1 h1 {
      font-size: 42px;
    }
  
    .mystoryCard2 {
      height: 30%;
    }
  
    .mystoryCard2 .text2 {
      width: 100%;
      padding: 15px;
    }
  
    .mystoryCard2 .text2 h6 {
      font-size: 22px;
    }
  
    .mystoryCard3 {
      height: 50%;
    }
  
    .mystoryCard4 .text3 {
      display: block;
    }
  
    .mystoryCard5 .text3 {
      width: 100%;
    }
  
    .mystoryCard5 .text3 h1 {
      font-size: 50px;
    }
  
    .programImg .banner1 img {
      width: 100%;
    }
  
    .programImg .programCard {
      position: absolute;
      top: -7px;
      left: 7px;
    }
  
    .programCard h2 {
      font-size: 12px;
      text-align: center;
    }
  
    .programCard1 {
      padding-top: 15px;
    }
  
    .programCard1 .peramhfgfg {
      text-align: left;
    }
  
    /* .programCard2{
    padding-top: 20px;
  } */
    .programCard2 .programText1 {
      display: block;
    }
  
    .programCard2 .programText1 .left2 .left2In {
      width: 90%;
      height: auto;
    }
  
    .programCard2 .programText1 .left2 .left2In h1 {
      font-size: 20px;
      margin-top: -95px;
    }
  
    .programCard2 .programText1 .right2 img {
      height: auto;
    }
  
    .programCard3 {
      padding-top: 10px;
    }
  
    .programCard3 .programText2 {
      display: block;
      height: auto;
    }
  
    .programCard3 .programText2 .right3 .right3In {
      height: auto;
    }
  
    .programslider {
      padding-top: 20px;
    }
  
    .programCard4 .programText3 {
      width: 100%;
    }
  
    .programCard4 .programText3 h3 {
      font-size: 20px;
    }
  
    .programCard4 .programText3 h1 {
      font-size: 33px;
    }
  
    .programCard4 .programText3 .programCard4Btn {
      font-size: 31px;
    }
  
    .myapprochImg .banner2 img {
      width: 100%;
    }
  
    .myapprochImg .myapprochCard {
      position: absolute;
      top: 91px;
      right: 33px;
    }
  
    .myapprochImg .myapprochCard h2 {
      font-size: 40px;
    }
  
    .myapprochCard1 {
      overflow: hidden;
      height: auto;
      margin-bottom: -60px;
      /* padding: 15px; */
    }
  
    .myapprochCard1 .myapprochText h1 {
      margin-top: -57px;
      font-size: 24px;
      text-align: center;
    }
  
    .myapprochCard2 {
      height: auto;
    }
  
    .myapprochCard2 .myapprochText1 {
      display: block;
    }
  
    .myapprochCard2 .myapprochText1 .left3In {
      width: 100%;
      height: auto;
      padding: 20px;
    }
  
    .myapprochCard2 .myapprochText1 .left3In h1 {
      font-size: 56px;
    }
  
    .myapprochCard2 .myapprochText1 .left3In h3 {
      font-size: 25px;
    }
  
    .myapprochCard2 .myapprochText1 .right3 {
      width: 100%;
    }
  
    .myapprochCard3 {
      height: auto;
    }
  
    .myapprochCard3 .myapprochText2 {
      width: 100%;
      padding: 15px;
    }
  
    .myapprochCard3 .myapprochText2 h2 {
      text-shadow: none;
      font-size: 40px;
    }
  
    .myapprochCard3 .myapprochText2 p {
      font-size: 25px;
    }
  
    .myapprochslider {
      padding-top: 15px;
    }
  
    .myapprochCard4 {
      height: auto;
    }
  
    .myapprochCard4 .myapprochText3 {
      display: block;
      height: auto;
    }
  
    .programCard4 {
      height: auto;
    }
  
    .programCard4 .myapprochText3 {
      width: 100%;
    }
  
    .moreCard {
      height: 100vh;
    }
  
    .moreCard .moreText {
      display: grid;
      grid-template-columns: 6fr 0fr;
    }
  
    .moreCard .moreText .moreLeft .top {
      display: flex;
      line-height: 40px;
      text-align: justify;
  
      /* justify-content: center;
    align-items: center; */
    }
  
    .moreCard {
      text-align: justify;
      font-size: 2px;
      font-weight: 100;
      /* width: 100%; */
      height: 973px;
      background-color: rgb(4, 14, 40);
    }
  
    .moreCard .moreText .moreRight {
      display: none;
    }
  
    .moreText .moreLeft .top {
      padding: 10px;
    }
  
    .moreText .moreLeft .top .menu .morePageLink {
      text-decoration: none;
      font-size: 8px;
    }
  
    .moreText .moreLeft .top1 .utilityMenu .morePageLink1 {
      font-size: 8px;
    }
  }
  @media screen and (max-width: 420px) {
    .imgCard {
      width: 45%;
      height: 90%;
      top: 20px !important;
      left: 200px !important;
      bottom: 10px;
      word-spacing: 1px;
      position: absolute;
      padding-bottom: 0px;
    }
  }
  
  @media screen and (max-width: 360px) {
    .mystoryCard1 {
      margin-bottom: 5px;
    }
    .imgCard {
      width: 42%;
      height: 85%;
      top: 14px !important;
      left: 175px !important;
      bottom: 30px;
      word-spacing: 1px;
      position: absolute;
      padding-bottom: 0px;
    }
    .imgCard .imgCardText {
      text-align: center;
      padding-top: 0px;
      letter-spacing: 1.5px;
      font-weight: bold;
      
      font-size: 12px;
    }
  
    .imgCard .imgCardTextHeadPara {
      text-align: center;
      color: #1d1d1d;
      
      font-weight: 500;
      font-size: 10px;
    }
    .imgCard .imgCardTextPara {
      padding: 0px;
    }
    .imgCard .imgCardTextPara p {
      text-align: center;
      color: #1d1d1d;
      
      font-weight: 400;
      font-size: 8px;
      /* border: 1px solid red; */
      padding: 0px;
    }
    .imgCard .imgcardButton {
      padding: 0px;
      font-size: 8px;
      font-weight: bold;
      border-radius: 5px;
      color: #112042;
      /* border: 1px solid red; */
    }
    .imgCard .dashbelow {
      width: 206px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 0px;
    }
    .box1 {
      padding-top: 20px;
    }
  
    .mystoryslider {
      padding-top: 10px;
    }
  
    .mystoryCard3 .mystorylogo {
      height: auto;
      display: flex;
      flex-direction: column;
    }
  
    .mystoryCard3 .mystorylogo .mystoryImg {
      margin: 0px;
      width: 58%;
      padding: 10px;
      height: auto;
    }
  
    .mystoryCard4 .text3 {
      display: block;
    }
  
    .mystoryCard5 .text3 h3 {
      font-size: 17px;
    }
  
    .mystoryCard5 .text3 h1 {
      font-size: 36px;
      text-shadow: none;
    }
  
    .programCard1 {
      padding-top: 15px;
    }
  
    .programCard1 .peramhfgfg {
      text-align: justify;
    }
  
    .programCard3 {
      padding-top: 10px;
    }
  
    .programslider {
      padding-top: 20px;
    }
  
    .programCard2mob {
      padding-top: 20px;
    }
  
    .programImg .programCard .h2 {
      font-size: 9px;
      text-align: center;
    }
  
    .myapprochImg {
      width: 100%;
    }
  
    .myapprochImg .myapprochCard {
      position: absolute;
      top: 80px;
      right: 30px;
    }
  
    .myapprochImg .myapprochCard h2 {
      font-size: 25px;
    }
  
    .myapprochCard1 {
      height: auto;
      padding: 15px;
    }
  
    .myapprochCard1 .myapprochText h1 {
      font-size: 30px;
    }
  
    .myapprochCard2 {
      height: auto;
    }
  
    .myapprochCard2 .myapprochText1 {
      display: block;
    }
  
    .myapprochCard2 .myapprochText1 .left3In {
      height: auto;
    }
  
    .myapprochCard2 .myapprochText1 .left3In h1 {
      font-size: 24px;
    }
  
    .myapprochCard2 .myapprochText1 .left3In h3 {
      font-size: 10px;
    }
  
    .myapprochCard3 {
      height: auto;
    }
  
    .myapprochCard3 .myapprochText2 {
      width: 100%;
    }
  
    .myapprochCard3 .myapprochText2 h2 {
      text-shadow: none;
      font-size: 28px;
    }
  
    .myapprochCard3 .myapprochText2 p {
      font-size: 14px;
    }
  
    .programCard4 {
      height: auto;
    }
  
    .programCard4 .programText3 h1 {
      text-shadow: none;
    }
  
    .myapprochCard4 {
      height: auto;
    }
  
    .myapprochCard4 .myapprochText3 {
      width: 100%;
      display: block;
    }
  
    .moreText .moreRight {
      display: none;
    }
  
    .moreRightCard {
      display: none;
    }
  
    .moreText .moreLeft .top {
      padding: 10px;
    }
  
    .moreText .moreLeft .top .menu .morePageLink {
      text-decoration: none;
      font-size: 26px;
    }
  
    .moreText .moreLeft .top1 .utilityMenu .morePageLink1 {
      font-size: 14px;
    }
  }
  
  /* ================================Media query End=============================== */
  
  /* .liststyle{
    list-style: none;
    text-decoration: none;
  } */